import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Link from '../../AppLink';

import CheckIcon from '../../../icons/done.svg';

// Router
import { paths, getRoute } from '../../../entry/routes';

import styles from './CheckItem.styl';

const cx = classNames.bind(styles);

export default function CheckItem(props) {
  const { criteria, check, goods, code, idx } = props;

  const hasGoods = goods.length > 0;
  const Component = hasGoods ? Link : 'div';
  const to = goods ? getRoute(paths.stockConditions, idx, code) : getRoute(paths.home);

  return (
    <Component
      to={to}
      className={cx('CheckItem', {
        CheckItem_checked: check,
      })}
    >
      <div className={cx('CheckItem__criteria')}>
        <CheckIcon className={cx('CheckItem__icon')} />
        {criteria}
      </div>
    </Component>
  );
}

CheckItem.defaultProps = {
  goods: [],
};

CheckItem.propTypes = {
  criteria: PropTypes.string.isRequired,
  check: PropTypes.bool.isRequired,
  goods: PropTypes.array,
  code: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
};
