import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

//Utils
import { setGlobalMessage } from '../../utils/setGlobalMessage';

// State
import { clearStock, applyStock, clearStockErrors } from '../../state/modules/stock/actions';
import {
  getConditionsSelector,
  getRewardsSelector,
  getProgressSelector,
  getIsAllConditionsCheckedSelector
} from '../../state/modules/stock/selectors';

// Components
import CheckItem from './CheckItem';
import RewardItem from './RewardItem';

// Icons
import ClearIcon from '../../icons/delete.svg';

// Styles
import styles from './PromocodeWidget.styl';

const cx = classNames.bind(styles);

export default function PromocodeWidget(props) {
  const { className, inputClassname, withProgressbar } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    isApplying,
    isApplied,
    error,
    data: { code }
  } = useSelector(state => state?.stock);
  const conditions = useSelector(getConditionsSelector);
  const rewards = useSelector(getRewardsSelector);
  const allConditionsChecked = useSelector(getIsAllConditionsCheckedSelector);
  const progressValue = useSelector(getProgressSelector);
  const list = useSelector(state => state.gifts?.list);
  const stockCode = useSelector(state => state.stock?.data?.code);
  const compositionCart = useSelector(state => state.cart?.composition);
  const compositionHasGift = useMemo(() => compositionCart?.find(good => good.type === 'bonus'), [compositionCart]);

  const [promocodeValue, setPromocodeValue] = useState('');
  const [isClearing, setIsClearing] = useState(false);

  const hasConditions = conditions.length > 0;
  const hasRewards = rewards.length > 0;

  const isDisabled = useMemo(() => !!stockCode, [stockCode]);

  const onPromocodeChange = e => {
    dispatch(clearStockErrors());
    setPromocodeValue(e.target.value);
  };

  const onPromocodeFocus = () => {
    dispatch(clearStockErrors());
  };

  // сбрасываем промокод, если он применен
  const onClearStock = async () => {
    setIsClearing(true);
    await dispatch(clearStock());
    setIsClearing(false);
    setPromocodeValue('');
  };

  const onApplyStock = async () => {
    if (promocodeValue) {
      //дизейблим кнопку применения промо через isClearing
      await setIsClearing(true);
      await dispatch(applyStock({ stock_code: promocodeValue }));
      setIsClearing(false);
    }
  };

  useEffect(() => {
    if (list.length > 0 && isDisabled && compositionHasGift) {
      setGlobalMessage(intl.formatMessage({ id: 'papabonus.gifts.disabled' }));
    }
  }, [list, isDisabled]);

  return (
    <div className={cx('PromocodeWidget', className)} data-test-id="promocode_widget">
      {withProgressbar && hasConditions && (
        <div className={cx('PromocodeWidget__progress')}>
          <div
            className={cx('PromocodeWidget__progress-scale')}
            style={{ transform: `scaleX(${progressValue})` }}
          />
        </div>
      )}
      <div className={cx('PromocodeWidget__field', inputClassname)}>
        <input
          className={cx('PromocodeWidget__input', {
            PromocodeWidget__input_applied: isApplying || isApplied,
            PromocodeWidget__input_error: error
          })}
          value={isApplied ? code : promocodeValue}
          onChange={onPromocodeChange}
          onFocus={onPromocodeFocus}
          type="text"
          placeholder={intl.formatMessage({ id: 'field.promocode.placeholder' })}
          onKeyDown={e => e.key === 'Enter' && !isApplied && onApplyStock()}
          readOnly={isApplied}
        />
        <button
          className={cx('PromocodeWidget__button', {
            PromocodeWidget__button_applied: isApplying || isApplied
          })}
          onClick={isApplying || isApplied ? onClearStock : onApplyStock}
          disabled={isClearing}
          type="button"
        >
          {!isApplied && !(isApplying || isClearing) && (
            <div className={cx('PromocodeWidget__button-arrow')} />
          )}
          {isApplied && !(isApplying || isClearing) && (
            <div className={cx('PromocodeWidget__button-clear')}>
              <ClearIcon />
            </div>
          )}
          {(isApplying || isClearing) && <div className={cx('PromocodeWidget__spinner')} />}
        </button>
      </div>
      {error && (
        <div className={cx('PromocodeWidget__error')} dangerouslySetInnerHTML={{ __html: error }} />
      )}
      {hasConditions && (
        <div className={cx('PromocodeWidget__conditions')}>
          {conditions.map((condition, index) => (
            <CheckItem key={index} idx={index} code={code} {...condition} />
          ))}
        </div>
      )}
      {hasRewards && (
        <div className={cx('PromocodeWidget__conditions')}>
          {rewards.map((reward, index) => (
            <RewardItem
              key={index}
              idx={index}
              {...reward}
              code={code}
              allConditionsChecked={allConditionsChecked}
            />
          ))}
        </div>
      )}
    </div>
  );
}

PromocodeWidget.defaultProps = {
  className: '',
  inputClassname: '',
  withProgressbar: true
};

PromocodeWidget.propTypes = {
  className: PropTypes.string,
  inputClassname: PropTypes.string,
  withProgressbar: PropTypes.bool
};
